<template>
  <b-card>
    <div class="d-flex justify-content-start mb-1">
      <b-form-group class="mr-2 mw-100"  label="Busca" label-for="filterSearch">
        <b-input-group class="input-group-merge d-flex flex-nowrap mb-2">
          <b-input-group-prepend class="cs-select">
            <v-select v-model="search" :options="searchOptions" :clearable="false" />
          </b-input-group-prepend>
          <b-form-input
            id="x2"
            placeholder="Buscar"
            class="px-1 w-100 mw-250"
            @keypress.enter="updatePayload($event.target.value)"
          />
          <b-input-group-append is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-form-group class="mr-2 mw-100 "  label="Data da Solicitação" label-for="solicitacoesCreatedAt">
        <div class="input-icon">
          <flat-pickr
            v-model="filterCreatedAt"
            class="form-control date-picker"
            :config="{ dateFormat: 'd/m/Y'}"
            placaeholder="Data da solicitação"
          />
          <feather-icon icon="CalendarIcon" size="22" class="icon-position text-success" />
        </div>
      </b-form-group>
      <b-form-group class="mr-2 mw-100 "  label="Status" label-for="filterStatus">
        <v-select v-model="filterStatus" :options="[ 'pendente', 'emitida', 'rejeitada']" :clearable="true" style="width: 150px"/>
      </b-form-group>
      <b-form-group  class="mr-2 mw-100" label="Número série" label-for="searchSerie">
        <b-form-input
            id="x3"
            v-model="searchSerie"
            placeholder="Buscar por numero série"
            class="px-1 w-100 mw-250"
            @keypress.enter="updatePayloadEmissaoSerie('numero_serie')"
          />
      </b-form-group>
      <b-form-group  class="mr-2 mw-100" label="Numero emissão" label-for="searchEmissao">
        <b-form-input
            id="x"
            v-model="searchEmissao"
            placeholder="Buscar por numero emissão"
            class="px-1 w-100 mw-250"
            @keypress.enter="updatePayloadEmissaoSerie('numero_emissao')"
          />
      </b-form-group>
    </div>
    <b-table responsive :items="items" :fields="fields">
      <template #cell(status)="data">
        <div v-html="statusBadge(data.item.status)"></div>
      </template>
      <template #cell(actions)="data">
        <b-link
          @click="$router.push({ name: 'admin.emissions_request.show', params: { id: data.item.id } })"
          class="text-secondary font-weight-bold"
          title="Visualizar"
        >
          <feather-icon icon="EyeIcon" size="24" />
        </b-link>
      </template>
    </b-table>

    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <div class="d-flex justify-content-between align-items-center">
          <label class="mb-0 mr-1">Mostrar</label>
          <v-select v-model="perPage" :options="perPageOptions" :clearable="false" />
          <label class="mr-2 ml-1">registros</label>
        </div>
        <div class="d-flex align-items-center">
          <span class="text-muted"> {{ showing }} </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
          aria-controls="my-table"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>

    <datatable-filters-handler-sidebar
      :is-task-handler-sidebar-active="showFiltersButton"
      @closeSideBar="showFiltersButton = false"
      @updateFilterValues="updateFilterValues"
    />
  </b-card>
</template>

<script>
import {
  dateTimeToDateFormatter,
  moneyFormatDatatable,
  dateFormatter
} from '@core/comp-functions/data_visualization/datatable'
import {
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BButton,
  BButtonGroup,
  BPagination,
  BTable,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BLink
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatatableFiltersHandlerSidebar from '@/views/common/crud/components/DatatableFiltersHandlerSidebar.vue'
import EmissionRequest from '@/services/emission_request'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'EmissionsRequest',
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BInputGroup,
    BButton,
    BButtonGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    vSelect,
    BFormInput,
    BPagination,
    DatatableFiltersHandlerSidebar,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    flatPickr,
    BLink
  },
  data() {
    return {
      showFiltersButton: false,
      items: [],
      fields: [
        {
          label: 'Debenturista',
          key: 'debenturista.nome_fantasia',
          sortable: false,
        },
        {
          label: 'CNPJ',
          key: 'debenturista.cnpj',
          sortable: false,
        },
        {
          label: 'Número Série',
          key: 'numero_serie',
          sortable: false,
        },
        {
          label: 'Número Emissao',
          key: 'numero_emissao',
          sortable: false,
        },
        {
          label: 'Valor Unitário',
          key: 'valor_nominal_unitario',
          sortable: false,
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Valor Total',
          key: 'valor_total_integralizado',
          sortable: false,
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Solicitada em',
          key: 'created_at',
          sortable: false,
          formatter: dateTimeToDateFormatter,
        },
        {
          label: 'status',
          key: 'status',
          sortable: false,
        },
        {
          label: 'Ações',
          key: 'actions',
          sortable: false,
        }
      ],
      currentPage: 1,
      search: {
        label: 'E-mail do usuário cedente',
        value: 'user_email',
      },
      searchQuery: {
        key: '',
        value: '',
      },
      perPage: 10,
      totalRows: 0,
      selectedButton: 'all',
      searchSerie: '',
      searchEmissao: '',
      searchOptions: [
        {
          label: 'E-mail do usuário cedente',
          value: 'user_email',
        },
        {
          label: 'Nome do usuário cedente',
          value: 'user_nome',
        },
        {
          label: 'CNPJ do debenturista',
          value: 'debenturista_cnpj',
        },
        {
          label: 'Razão social do debenturista',
          value: 'debenturista_razao_social',
        },
        {
          label: 'Nome fantasia do debenturista',
          value: 'debenturista_nome_fantasia',
        },
        {
          label: 'Número de série',
          value: 'numero_serie',
        }
      ],
      filterCreatedAt: '',
      filterStatus: '',
      filterCedenteId: '',
      perPageOptions: [10, 25, 50, 100],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    updatePayload(value) {
      this.searchQuery.key = this.search.value
      this.searchQuery.value = value
    },
    updatePayloadEmissaoSerie(value) {
      this.searchQuery.key = value
      value === 'numero_emissao' ? this.searchQuery.value = this.searchEmissao : this.searchQuery.value = this.searchSerie
    },
    async getData() {
      try {
        const { data } = await EmissionRequest.fetch(this.payload)
        this.items = data.data
        this.currentPage = data.current_page
        this.totalRows = data.total
      } catch (error) {
        console.log(error)
      }
    },
    updateFilterValues(values) {
      this.datatableFilterValues = values
    },
    selectButton(type) {
      this.selectedButton = type
    },
    statusBadge(status) {
      const variant = {
        pendente: 'light text-dark',
        emitida: 'success',
        rejeitada: 'danger',
      }[status]
      const text = {
        pendente: 'Pendente',
        emitida: 'Emitida',
        rejeitada: 'Rejeitada',
      }[status]

      return `<span class="badge badge-light-${variant} border-${variant} badge-pill">${text}</span>`
    },
  },
  computed: {
    payload() {
      let payload = {
        page: this.currentPage,
        per_page: this.perPage,
      }

      if (this.filterStatus){ payload.status = this.filterStatus }
      if (this.filterCedenteId){ payload.cedente_id = this.filterCedenteId }
      if (this.filterCreatedAt){ payload.created_at = this.filterCreatedAt }


      if (this.searchQuery.key && this.searchQuery.value)
        payload[this.searchQuery.key] = this.searchQuery.value

      return payload
    },
    showingStart() {
      if (this.totalRows === 0) return 0

      return this.currentPage === 1 ? 1 : (this.currentPage - 1) * this.perPage + 1
    },
    showingEnd() {
      let end = this.currentPage * this.perPage
      if (end > this.totalRows) end = this.totalRows

      return end
    },
    showing() {
      return `Mostrando de ${this.showingStart} a ${this.showingEnd} de ${this.totalRows} registros`
    },
  },
  watch: {
    payload() {
      this.getData()
    },
  },
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.mw-250 {
  max-width: 250px;
}

.input-icon {
  position: relative;
  display: inline-block;
}

.icon-position {
  position: absolute;
  right: 10px;
  top: 8px;
}
</style>
